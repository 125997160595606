import { createGlobalStyle } from 'styled-components';
import { Theme } from './Theme';

export default createGlobalStyle<{ theme: Theme }>`
  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.background.primary};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
